















import { Component, Vue } from "vue-property-decorator";
@Component({})
export default class Child extends Vue {
  public refs!: { [x: string]: any };
  input = 123;
  mounted() {
    console.log("Bowen: Child -> mounted -> mounted");
  }
  created() {
    console.log("Bowen: Child -> created -> created");
  }
  updated() {
    console.log("Bowen: Child -> updated -> updated");
  }
  destroyed() {
    console.log("Bowen: Child -> destroyed -> destroyed");
  }
}
