


















import { Component, Vue } from "vue-property-decorator";
import Child from "./child.vue";
@Component({
  components: {
    Child,
  },
})
export default class Father extends Vue {
  public refs!: { [x: string]: any };
  input = 666;
  mounted() {
    console.log("Bowen: Father -> mounted -> this.$options", this);
    console.log("Bowen: Father -> mounted -> mounted");
  }
  created() {
    console.log("Bowen: Father -> created -> created");
  }
  getReUpdate() {
    console.log("Bowen: Father -> getReUpdate -> Father了渲染");
    return "Father了渲染";
  }
  updated() {
    console.log("Bowen: Father -> updated -> updated");
  }
  forceUpdate() {
    console.log("Bowen: Father -> forceUpdate -> forceUpdate");
    this.$forceUpdate();
  }
  destroyed() {
    console.log("Bowen: Father -> destroyed -> destroyed");
  }
}
