













import { Component, Vue } from "vue-property-decorator";
@Component({})
export default class SlotTest extends Vue {
  public refs!: { [x: string]: any };
  mounted() {
    console.log("Bowen: Slot -> mounted -> mounted");
  }
  created() {
    console.log("Bowen: Slot -> created -> created");
  }
  updated() {
    console.log("Bowen: Slot -> updated -> updated");
  }
}
