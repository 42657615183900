



















import { Component, Vue } from "vue-property-decorator";
import Father from "./father.vue";
import SlotTeset from "./slot.vue";
import NumberInput from "./input.vue";

@Component({
  components: {
    Father,
    SlotTeset,
    NumberInput,
  },
})
export default class TestIndex extends Vue {
  public refs!: { [x: string]: any };
  show = true;
  value = 0;
  reLoad() {
    this.show = false;
    this.$nextTick(() => {
      this.show = true;
    });
  }

  input(value: number) {
    console.log("Bowen: TestIndex -> input -> value", value);
    this.value = value;
  }
  update() {
    (this.$refs.father as any).forceUpdate();
    console.log(
      "Bowen: TestIndex -> update -> this.$refs.father",
      this.$refs.father
    );
  }
  updated() {
    // console.log("Bowen: TestIndex -> updated -> updated");
  }
  mounted() {
    console.log("Bowen: TestIndex -> mounted -> mounted");
  }
  created() {
    console.log("Bowen: TestIndex -> created -> created");
  }
}
