










import E from "wangeditor";
import { Component, Vue } from "vue-property-decorator";
@Component({})
export default class Todo extends Vue {
  editor: any = null;
  handleClick() {
    const html = this.editor.txt.html();
    console.log(
      "Bowen ~ file: todo.vue ~ line 19 ~ Todo ~ handleClick ~ html",
      html
    );
  }
  mounted() {
    this.editor = new E("#div1");
    // 或者 const editor = new E( document.getElementById('div1') )
    this.editor.create();
  }
}
